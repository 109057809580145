import { AridDunes } from './arid-dunes';
import { Astro } from './astro';
import { HelpHero } from './help-hero';
import { TrailheadBanner } from './trailhead-banner';
import { TrailheadFirstStep } from './trailhead-first-step';
import { TrailheadBasics } from './trailhead-basics';
import { ReactComponent as AppExchange } from './appexchange.svg';
import { ReactComponent as AppleIcon } from './apple.svg';
import { ReactComponent as AppleIconRound } from './apple-round.svg';
import { ReactComponent as AstroAnimation } from './astro-animation.svg';
import { ReactComponent as AstroAvatar } from './astro.svg';
import { ReactComponent as AstroLogin } from './astro-login.svg';
import { ReactComponent as CodeyAnimation } from './codey-animation.svg';
import { ReactComponent as TrailheadConnectIcon } from './th-connect.svg';
import { ReactComponent as FacebookIcon } from './facebook.svg';
import { ReactComponent as FacebookIconRound } from './facebook-round.svg';
import { ReactComponent as TrailheadEarnIcon } from './th-earn.svg';
import { ReactComponent as EmailIcon } from './email.svg';
import { ReactComponent as EmailIconRound } from './email-round.svg';
import { ReactComponent as Events } from './events.svg';
import { ReactComponent as GoogleIcon } from './google.svg';
import { ReactComponent as GoogleIconRound } from './google-round.svg';
import { ReactComponent as HelpPortal } from './help_portal.svg';
import { ReactComponent as IdeaExchange } from './ideaexchange.svg';
import { ReactComponent as TrailheadLearnIcon } from './th-learn.svg';
import { ReactComponent as LinkedInIcon } from './linkedin.svg';
import { ReactComponent as LinkedInIconRound } from './linkedin-round.svg';
import { ReactComponent as SalesforceLogo } from './salesforce.svg';
import { ReactComponent as SalesforceIcon } from './salesforce-icon.svg';
import { ReactComponent as SalesforceIconRound } from './salesforce-round.svg';
import { ReactComponent as TrailblazerCommunityIcon } from './trailblazer-community.svg';
import { ReactComponent as TrailblazerLogo } from './trailblazer-me.svg';
import { ReactComponent as TrailheadIcon } from './trailhead.svg';
import { ReactComponent as TrailheadLogo } from './trailhead-logo.svg';
import { ReactComponent as TrailblazerMeLogo } from './_tbme.svg';

export const Images = {
	AridDunes,
	Astro,
	AstroLogin,
	HelpHero,
	SalesforceLogo,
	Trailblazer: {
		Logo: TrailblazerLogo,
		Me: TrailblazerMeLogo,
	},
	Trailhead: {
		Banner: TrailheadBanner,
		Basics: TrailheadBasics,
		FirstStep: TrailheadFirstStep,
		Logo: TrailheadLogo,
	},
};

export const Animations = {
	Astro: AstroAnimation,
	Codey: CodeyAnimation,
};

export const Icons = {
	AppExchange,
	Apple: {
		Regular: AppleIcon,
		Round: AppleIconRound,
	},
	AstroAvatar,
	Facebook: {
		Regular: FacebookIcon,
		Round: FacebookIconRound,
	},
	Email: {
		Regular: EmailIcon,
		Round: EmailIconRound,
	},
	Events,
	Google: {
		Regular: GoogleIcon,
		Round: GoogleIconRound,
	},
	HelpPortal,
	IdeaExchange,
	LinkedIn: {
		Regular: LinkedInIcon,
		Round: LinkedInIconRound,
	},
	Salesforce: {
		Regular: SalesforceIcon,
		Round: SalesforceIconRound,
	},
	Trailhead: TrailheadIcon,
	TrailheadConnect: TrailheadConnectIcon,
	TrailheadEarn: TrailheadEarnIcon,
	TrailheadLearn: TrailheadLearnIcon,
	TrailblazerCommunity: TrailblazerCommunityIcon,
};
